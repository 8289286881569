<template>
    <div id="search-form" v-if="mixinIsAuthenticated()">

        <b-form class="form-content-mobile">
            <div class="form-toggler">
                <div class="input-with-icon-wrapper" @click="toggleMobileSearch">
                    <div class="input-icon">
                      <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.608 0.856018C7.85232 0.857872 5.21005 1.9533 3.26141 3.90175C1.31278 5.8502 0.217085 8.49236 0.214966 11.248C0.214966 16.98 6.26497 23.494 10.608 27.228C14.95 23.494 21 16.98 21 11.248C20.9979 8.49236 19.9022 5.8502 17.9535 3.90175C16.0049 1.9533 13.3626 0.857872 10.607 0.856018H10.608ZM10.607 13.825C9.83977 13.825 9.08982 13.5975 8.45192 13.1713C7.81402 12.745 7.31683 12.1392 7.02324 11.4305C6.72965 10.7217 6.65283 9.94171 6.80251 9.18925C6.95218 8.4368 7.32162 7.74564 7.86411 7.20316C8.40659 6.66067 9.09777 6.29123 9.85022 6.14156C10.6027 5.99188 11.3826 6.0687 12.0914 6.36229C12.8002 6.65588 13.406 7.15306 13.8322 7.79095C14.2585 8.42885 14.486 9.17882 14.486 9.94601C14.4857 10.9747 14.0769 11.9612 13.3495 12.6886C12.6222 13.416 11.6357 13.8247 10.607 13.825Z" fill="#0071BC" />
                      </svg>
                    </div>
                    <div class="input-with-icon">
                        <b-form-input
                            id="input-1"
                            type="email"
                            disabled
                            :placeholder="(geo.label == '' ? 'Inserisci una località' : geo.label)"
                        >
                        </b-form-input>
                    </div>
                </div>

                <!-- eccezione in home/home-in-vadobay: icona lente al posto dell'icona filtri ed apertura ricerca al posto dei filtri -->
                <div class="buttons">
                    <div class="toggle-search-wrapper">
                        <div id="toggle-search-settings" class="icon" @click="toggleMobileSearch">
                            <SvgChevronRight class="open-search-settings" />
                        </div>
                    </div>
                    <div class="toggle-filters-wrapper" v-if="!triggerMobileSearch" @click="toggleMobileFilters" >
                        <SvgFilters class="open-search-settings" />
                    </div>
                </div>
            </div>
            <!--<before-header v-if="showMobileSearch | showMobileFilter" />-->
            <Fields v-on:submit-clicked="submitClicked" v-if="showMobileSearch" />
            <Filters v-on:category-changed="categoryChanged" v-on:filter-changed="filterChanged" v-if="showMobileFilter" :resultsFiltered="resultsFiltered" />
        </b-form>

        <b-form class="form-content-desktop">
            <Fields :offset="offset" v-on:submit-clicked="submitClicked" :elementSlug="elementSlug"/>
            <!--<Filters v-on:filter-changed="filterChanged" />-->
        </b-form>

    </div>
</template>

<script>
import SvgFilters from '@/components/svg/filters'
import SvgChevronRight from '@/components/svg/chevron-right'

export default {
    name: 'search-form',
    components:
    {
        Fields: () => import('./fields').then(function(Fields) {
            window.console.log('async: PageHeader Component loaded')
            return Fields
        }),
        Filters: () => import('./filters').then(function(Filters) {
            window.console.log('async: Filters Component loaded')
            return Filters
        }),
        SvgFilters,
        SvgChevronRight,
    },
    props:{
        offset: Number,
        elementSlug: String,
        submitCallback: Function,
        resultsFiltered: Array,
        ignoreMapRedirect: {
          type: Boolean,
          default: false,
        }
    },
    computed:
    {
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        }
    },

    data() {
        return {
            showMobileFilter: false,
            showMobileSearch: false,
            triggerMobileSearch: this.$route.name == 'homepage' | this.$route.name == 'in-vadobay' | this.$route.name == 'in-vivamod', // decide se aprire il menu di ricerca o il menu filtri
        }
    },

    methods:
    {
        submitClicked()
        {

            // al cerca azzero offset autoscroll
            // così se dal dettaglio viene fatta una nuova ricerca, non si viene autoscrollati in basso
            this.mixinSendMutation('setScroll', window.pageYOffset)

            this.submitCallback()
            this.$emit('submit-search')
            this.showMobileSearch = false
            document.body.classList.remove('mobile-search-on')
        },
        filterChanged()
        {
            this.$emit('filter-changed')
            this.showMobileFilter = false
            document.body.classList.remove('mobile-filter-on')
        },
        categoryChanged()
        {
            this.$emit('category-changed')
        },
        toggleClass(element, className) {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            } else {
                element.classList.add(className);
            }
        },
        toggleMobileFilters()
        {
            this.toggleClass(document.body,'mobile-filter-on')
            this.showMobileFilter = !this.showMobileFilter
            this.showMobileSearch = false
            document.body.classList.remove('mobile-search-on')
        },
        toggleMobileSearch()
        {
            this.toggleClass(document.body,'mobile-search-on')
            this.showMobileSearch = !this.showMobileSearch
            this.showMobileFilter = false
            document.body.classList.remove('mobile-filter-on')
        }
    },
    mounted(){
      if(this.ignoreMapRedirect){
        let geo = this.geo
        geo.ignoreMapRedirect = true
        this.geo = geo
      }

    }
}
</script>

<style lang="scss">
    @import './src/scss/_search-form.scss';

    #hero #search-form
    {
        // deve mantenere il colore fisso in vadobay e il gradiente in vivamod
        // senza l'if verrebbe usato il gradiente sass in vadobay ed il gradiente --var in vivamod
        @if($customize)
        {
            @include customize-gradient;
        }
    }

    //#hero 
    #search-form
    {
        position:relative;
        .form-toggler
        {
            display:block;
        }
        .buttons
        {
            position:absolute;
            top:3px;
            right:0;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            height:50px;
            font-size: 1rem;

            @at-root .page-homepage & {
              right: -20px;
            }
        }
        .toggle-search-wrapper,
        .toggle-filters-wrapper
        {
            width:34px;
            height:34px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg
            {
                width:20px;
                height:auto;
            }            
        }
        .toggle-search-wrapper
        {
            transform:rotate(90deg);
            transform-origin: center center;
            transition:$transition-base;
            box-shadow: 5px 5px 6px rgba(204, 204, 204, 0.749);
            border-radius: 50px;
            background: $white;
            margin-right: 5px;
        }
        .toggle-filters-wrapper
        {
          box-shadow: 5px 5px 6px rgba(204, 204, 204, 0.749);
          border-radius: 50px;
          background: $white;
          margin-right: 5px;
        }
        #toggle-search-settings
        {
            padding:0;
            margin-left:0 !important;
        }
        .form-toggler
        {
            padding-right:90px;
        }
    }

    body.mobile-search-on
    {
        overflow: hidden;
        #hero
        {
            overflow: visible;
        }
        #hero:after
        {
            opacity:0;
        }
        //#hero 
        #search-form .toggle-search-wrapper
        {
            transform:rotate(-90deg);
        }
        .input-with-icon-wrapper
        {
            display:none !important;
        }
        .search-form-fields
        {
            margin-top:0 !important;
        }
        #typeahead-container
        {
            padding-right:80px;
        }
        .page-in-vivamod,
        .page-homepage
        {
            #typeahead-container
            {
                padding-right:50px;
            }
        }

        #search-form
        {
            overflow: hidden;
            padding-bottom:1rem;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        .search-form-fields
        {
            padding-right:8px; //box shadow tagliata
        }
    }

    /*body.mobile-filter-on {
      overflow: hidden;
    }*/

    .page-in-vivamod,
    .page-homepage {
      #search-form {
          .toggle-filters-wrapper
          {
              display:none;
          }
          .toggle-search-wrapper
          {
              right:14px;
              top:22px;
          }
          .form-toggler
          {
              padding-right:50px;
          }
      }
    }

    .page-homepage {
      #search-form {
        .buttons {
          width:80px;
        }
      }
    }

    .page-in-vivamod {
      #search-form {
        .buttons {
          width:50px;
        }
      }
    }
</style>